.pointer{
    cursor: pointer;
}

.header-container {
    position: fixed;
    right: 0;
    padding: 25px;
    z-index: 1000;
}

.MuiMenu-list {
    width: 180px;
}

.header-btn {
    cursor: pointer;
    padding: 8px;
    color: #ADB5BD;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 50px;
    border-radius: 25px !important;
    background: #FFFFFF !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
}

.header-link {
    color: black;
    text-decoration: none;
}

.header-link:hover {
    color: #44a3d5;
    text-decoration: none;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

@media screen and (max-width: 800px) {
    .container {
        padding: 1.5rem;
        max-width: 100%;
    }
}