

Button {
  text-transform: uppercase;
}

.btn{
  margin: 10px;
}

.secondary {
  color: #fff !important;
  background-color: #999b86 !important;
  border-color: #999b86 !important;
}

.secondary:hover {
  background-color: #666859 !important;
  border-color: #666859 !important;
}


.ww-btn {
  margin-top: 30px;
  width: 200px;
  border-radius: 6px; 
  text-transform: uppercase;
  align-self: center;
}

.btn-sx {
  width: 150px;
  border-radius: 6px; 
  text-transform: uppercase;
  align-self: center;
}

.btn-s {
  margin-top: 30px;
  width: 200px;
  border-radius: 6px; 
  text-transform: uppercase;
  align-self: center;
}

.btn-md {
  margin-top: 30px;
  width: 265px;
  border-radius: 6px; 
  text-transform: uppercase;
  align-self: center;
}


.ww-btn-primary{
  background-color: #BDCE09 !important;
  color: #FFF !important;
  border-color: #BDCE09 !important;
  border-width: 3px;
}
.ww-btn-primary:hover {
  background-color: #a1b00a !important;
  border-color: #a1b00a !important;
}

.ww-btn-secondary{
  background-color: #FFFFFF !important;
  color: #00B0DA !important;
  border-color: #00B0DA !important;
  border-width: 3px;
}
.ww-btn-secondary:hover {
  background-color: #d6eff5  !important;
  border-color: #00B0DA  !important;
}

