.main-container {
    padding: 5% 0 0 0;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ww-container-m {
    img {
        margin: 25px;
    }

    background: #FFFFFF;
    border-radius: 10px;
    padding: 0 0 55px 0;
    width: 600px;
    min-width: 350px;
    margin: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ww-container-xl {
    img {
        margin: 25px;
    }

    background: #FFFFFF;
    border-radius: 10px;
    padding: 0 0 55px 0;
    width: 820px;
    min-width: 350px;
    margin: 15px;
    display: flex;
    padding: 15px;

    flex-direction: column;
    align-items: center;
}

.ww-container-xl-nf {
    img {
        margin: 25px;
    }

    background: #FFFFFF;
    border-radius: 10px;
    padding: 0 0 55px 0;
    width: 820px;
    min-width: 350px;
    margin: 15px;
    padding: 15px;

    flex-direction: column;
    align-items: center;
}

.ww-container-xxl {
    img {
        margin: 25px;
    }

    background: #FFFFFF;
    border-radius: 10px;
    padding: 0 0 55px 0;
    min-width: 350px;
    margin: 15px;
    // display: flex;
    padding: 15px;

    flex-direction: column;
    align-items: center;
}

.validation-margin {
    margin: -5px 0 0 0;
}

.margin-s {
    margin: 10px;
}

.floating-button {
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 999;
}

.labelWithButton {
    display: flex;
}

.labelButton {
    width: fit-content;
}

.HContainer {
    display: flex;
    flex-direction: row;
    align-items: baseline;  
}

.VContainer {
    display: flex;
    flex-direction: column;
}

.titleButtonContainer {
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.labelWithButtonDetailContact {
    display: flex;
    align-items: baseline;
}