.register-container {
    margin: auto;
    display: flex;
    flex-direction: column;
    width: 25rem;
}

.container-inputs{
    display: flex;
}

.container-inputs-topmargen{
    margin-top: 10px !important;
}

.inputs-style{
    width: 345px;
    max-width: 345px;
    margin: 0 15px 0 15px;
    flex-direction: column;
    justify-content: center;
}

.inputs-style-2 {
    margin: 0 15px 0 15px;
    width: -webkit-fill-available;
}

.check-inputs{
    margin: 20px 0 0 25px;
}


@media screen and (max-width: 800px) {
    .container-inputs{
        display: flex;
        flex-direction: column;
    }

    .check-inputs{
        margin: 20px 0 15px 25px;
    }    

    .form-check-input{
        margin: 0 0 0 30px;
    }
}