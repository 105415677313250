body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  margin: 0 auto;
  background-color: #44A3D5;
  color: #555555;
  overflow: auto !important;
  padding-right: 0 !important;
}

.pointer {
  cursor: pointer;
}

.text-small {
  font-size: small;
}

.main-div {
  height: 100vh;
}

input {
  border: 2px solid #DDDDDD !important;
}

select {
  border: 2px solid #DDDDDD !important;
}

form {
  display: flex;
  flex-direction: column;

  input {
    width: 325px;
  }

  select {
    width: 325px;
  }

  label {
    text-transform: uppercase;
    font-size: 14px;
    margin: 5px;
  }

  svg {
    color: #fff;
  }
}

.icon-xl {
  font-size: 75px;
  color: #00B0DA;
  margin: 0 0 30px 0;
}

.react-datepicker {
  width: 243px !important;

  .react-datepicker__month-select {
    width: 100px !important;
  }

  .react-datepicker__year-select {
    width: 100px !important;
  }

  .react-datepicker__day--selected {
    background-color: #00B0DA;
  }
}

.react-datepicker__input-container {
  input {
    width: 100% !important;
    margin: 0 0 0 0px !important;
    height: calc(2.25rem + 2px) !important;
  }
}

.css-1in441m {
  width: 0px !important;
}


@media screen and (max-width: 800px) {
  .react-datepicker__input-container {
    input {
      width: 85% !important;
      margin: 0 0 0 25px !important;
      height: calc(2.25rem + 2px) !important;
    }
  }

  .autocomplete-styled-box {
    width: 294px !important;
    margin: 0 0 0 25px;
  }
}

.column-center {
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-items: center;
}

.autocomplete-styled-box {
  width: 100%;
}

.css-1ex1afd-MuiTableCell-root {
  padding: 3px 0 3px 10px !important;
}

.bold {
  font-weight: bold;
}

.react-datepicker-popper {
  z-index: 10;
}

.MuiInputLabel-root {
  text-transform: initial;
  font-family: 'Lato', sans-serif !important;
}

.MuiTableCell-head {
  font-weight: bold !important;
  text-transform: initial !important;
}

.MuiButton-SizeMedium {
  font-weight: bold !important;
  text-transform: initial !important;
}

.a-btn {
  width: 40px;
  height: 40px;
  background-color: #BDCE09;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin-top: 8px;
}

.a-btn:hover {
  background-color: #a1b00a;
}

.tss-1fz4yw6-MUIDataTableSelectCell-fixedLeft {
  position: relative !important;
}



@media screen and (max-width: 800px) {
  .sign-field {
    flex-direction: column;
  }

  .sign-field-fields {
    align-items: center;
    flex-wrap: wrap;
  }

  .sign-field-botons {
    width: 70%;
  }
}

.container-centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle-movile-steps {
  width: 80px;
  height: 80px;
  background-color: #aec9ea;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: x-large;
  border-radius: 50%;
}

.circle-movile-steps-selected {
  width: 80px;
  height: 80px;
  background-color: #3874cb;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: x-large;
  border-radius: 50%;
}

.circle-movile-steps:hover {
  opacity: 0.5;
}

.fade-in-out {
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

.hidden {
  display: none;
}

.circle-movile-steps-secondary {
  width: 40px;
  height: 40px;
  background-color: #aec9ea;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: x-large;
  border-radius: 50%;
}

.circle-movile-steps-secondary-sel {
  width: 40px;
  height: 40px;
  background-color: #3874cb;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: x-large;
  border-radius: 50%;
}

.movil {
  display: none !important;
}

.desktop {
  display: flex !important;
}



@media screen and (max-width: 700px) {
  .movil {
    display: flex !important;
  }

  .desktop {
    display: none !important;
  }
}

.row-files {
  display: flex;
  flex-direction: row;
  width: "100%";
}

.styled-button {
  display: inline-flex;
  align-items: center;
  padding: 10px 10px;
  background-color: #fff !important;
  color: #fff;
  cursor: pointer;
  border: 1px solid #00B0DA;
  border-radius: 5px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #b9d7de !important;
    border-color: #00B0DA;
  }
}

.styled-button-primary {
  display: flex;
  align-items: center;
  place-content: center;
  background-color: #BDCE09 !important;
  color: #FFF !important;
  border-color: #BDCE09 !important;
  border-width: 3px;

  width: 150px;
  height: 40px;
  text-align: center;
  border-radius: 6px;
  text-transform: uppercase;
  align-self: center;

  &:hover {
    background-color: #a1b00a !important;
    border-color: #a1b00a !important;
  }
}

.button-icon {
  color: #00B0DA;
  /* Cambia el color del icono */
  font-size: 1.1em;
  /* Ajusta el tamaño del icono */
}

// .floating-button-icon {
//   width: 100px;
//   height: 100px;
//   position: fixed;
//   z-index: 999;
//   right: 10px;
//   top: 50%;
//   transform: translateY(-50%);
// }

.floating-button-icon {
  width: 100px;
  height: 100px;
  position: fixed;
  z-index: 999;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  animation: latido 1s infinite alternate;
}

@media only screen and (max-width: 600px) {
  .floating-button-icon {
    right: 10px;
    bottom: 0;
    top: auto;
    transform: none;
    animation: latido 1s infinite alternate;
  }
}

@keyframes latido {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}