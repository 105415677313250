.gallery-preview{
    width: 220px;
    height: 235px;
    margin: 20px !important;
}

.gallery-container{
    width: 260px;
    display: flex;
    align-items: center;
}

.file-preview-view{
    width:550px;
    height:600px;
}

@media screen and (max-width: 650px) {
    .file-preview-view{
        width:350px;
    }
}