
.footer-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: url(../../../public/images/Components/footer-background.svg);
    background-size: cover;
    width: 100%;
    max-width: 100%;
    height: 50px;
    bottom: 0;
    position: absolute;
    
    box-sizing: border-box;
    
    p {
        font-size: initial;
        color: #CCCCCC;
    }
}

@media screen and (max-width: 800px) {
    p {
        font-size: small;
        text-align: center;
    }
    .footer-box {
        height: 70px;
    }
  }
  