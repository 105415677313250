.errorTitle {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 120px;
    line-height: 141px;

    color: #44A3D5;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.title {
    text-transform: uppercase;
    text-align: center;
    font-family: 'Raleway', sans-serif;
    color: #44A3D5;
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 29px;
    margin: 5px;
}