.postulation-state-container {
    background-color: #F8F4F4;
    border-radius: 10px;
    padding: 30px 30px 30px 30px;
    width: 700px;
    min-width: 300px;
}

.tittle-btn-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.course-name {
    display: flex;
}

.margin-1 {
    margin: 3px;
}

.container-inputs {
display: flex;
align-items: center;
}

.message-btn {
    background: #44A3D5!important;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    border-width: 0;
    color: white;
    font-size: x-large;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
}

.message-btn:hover {
    background: #3291c5!important;
}

.pill {
    //text-transform: uppercase;
    color: #fff;
    border-radius: 10px;
    padding: 10px 15px 10px 15px;
    margin-left: 10px;
}

.green {
    background-color: #63AC63;
}

.yellow {
    background-color: #BDCE09;
}

.blue {
    background-color: #44A1BC; 
}

.red {
    background-color: #BD362F;
}

.orange {
    background-color: #F99F1F;
}

.grey {
    background-color: #CCCCCC;
}

@media screen and (max-width: 800px) {
    .postulation-state-container {
        width: auto;
    }
}

.progress-component{
    margin: 80px 30px 25px 30px;
}

.circle-cont{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: -41px;
    z-index: 1;
}

.circle {
    width: 40px;
    height: 40px;
    background-color: #a7caed;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: x-large;
    border-radius: 50%;
}

.blue-circle {
    background-color: #1976d2;
}

.circle-container {
    display: flex;
    justify-content: space-between;
}

.mini-container{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.flex-control {
    flex-direction: column;
    align-items: center;
}