
.forgot-password{
    display: flex;
    flex-direction: column;
    align-items: center;

    a {
        color: #00B0DA;
        text-decoration: none;
    }
}

.title {
    text-transform: uppercase;
    font-family: 'Raleway', sans-serif;
    color: #44A3D5;
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 29px;
    margin: 0 0 20px 0;
}

.text {
    width: 345px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;

    color: #555555;
}

.text-center {
    width: 345px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;

    color: #555555;
}

.form-m{
    width: 345px;
    max-width: 345px;
}

.ModalContainerPWA {
    background: #fff;
}

.ModalContainerPWA{
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 3% 0 3%;
    bottom: 0;
    position: fixed;
    width: 100%;
}

.ButtonPWAClose{
    cursor: pointer;
}

.logoPWA{
    width: 100px;
}

.InnerModalContainerPWA {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.DownloadPWA {
    margin: 0 0 0 30px;
}

.ButtonEyeContainer{
    position: absolute;
    top: 6px;
    right: 10px;
    cursor: pointer;
}

.ButtonEye {
    color: #ADB5BD ;
}

@media screen and (max-width: 800px) {
    .form-control {
        width: 85% !important;
        margin: auto;
    }

    .input-group {
        width: 85% !important;
        margin: auto;
    }

    form label {
        margin: 0 0 5px 20px !important;
    }

    .ww-container-m img {
        margin: 80px 0 35px 0;
        width: 200px;
    }

    .validation-margin {
        margin: -5px 0 0 30px;
    }
}