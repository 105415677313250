$shadow-sm: 0px 10px 13px rgba(52, 58, 64, 0.25);

.videos-card {
    justify-content: space-around;
    align-items: flex-end;
  }
  
  video {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  
  .video-title {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: $shadow-sm;
  
    p {
      font-weight: bold;
      font-size: 20px;
      line-height: 25px;
  
      padding: 20px;
      margin: 0;
      color: #343a40;
    }
  
    a {
      align-self: flex-end;
      padding: 0 26px 10px 0;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
  
      text-decoration-line: underline;
      }
  }
  
  .wrapper {
    padding: 0.85rem;
    display: flex;
    flex-direction: column;
  }
  
  .youtube-player {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: contents;
    width: 100% !important;
    max-height: 250px !important;
    overflow: hidden;
  }
  
  @media (min-width: 700px) {
    .youtube-player {
      width: 100% !important;
      height: auto !important;
    }
    .wrapper {
      padding: 1.5rem 12rem 1.5rem 12rem;
      display: flex;
      flex-direction: column;
    }
  }
  
  .react-player {
      border-radius: 0.25rem;
      overflow: hidden;
    }

    